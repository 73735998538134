import { Injectable } from '@angular/core';
import * as firebaseone from 'firebase/app'
import {AngularFireAuth} from "angularfire2/auth";
import {AngularFirestore, AngularFirestoreDocument} from "angularfire2/firestore";
import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/switchMap'
import {RegisteredUser} from "./registration/RegisteredUser";
import {Router} from "@angular/router";
import {RegistrationService} from "./registration.service";

@Injectable()
export class AuthserviceService {


  constructor(private afs: AngularFireAuth) {
  }

  async signUP(user: RegisteredUser,doReset:boolean){
    firebaseone.auth().createUserWithEmailAndPassword(user.email, user.pass).then((user) => {
      if (doReset){
        this.resetPass(user.email);
      }
      user.sendEmailVerification().then(function () {
        console.log('Email Sent')
      }).catch(function (error) {
        console.log('An error occured')
      })
    }).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;

      console.log('Sign up error')
      console.log('Error Code: ' + errorCode + ',' + ' Error Message: ' + errorMessage);

      if (errorCode == 'auth/email-already-in-use'){
        alert('Email already registered please sign in instead')
        //this.signIN(user.email,user.pass);
      }
    })
  }

  signIN(email:string, password:string){
    firebaseone.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;

      console.log('Sign in error')
      console.log('Error Code: ' + errorCode + ',' + ' Error Message: ' + errorMessage);

      if (errorCode == 'auth/wrong-password'){
        alert('Wrong Email or Password please try again')
      }
    })

    console.log('Signed In')
  }

  resetPass(email:string){
    var auth = firebaseone.auth();
    var emailAddress = email;

    auth.sendPasswordResetEmail(emailAddress).then(function() {
      console.log('Password Reset Email sent')
      alert('User Successfully Added')
    }).catch(function(error) {
      alert('There is a problem')
      console.log('Error: ' + error)
    });
  }
}
