import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-what-is-indoor-cricket',
  templateUrl: './what-is-indoor-cricket.component.html',
  styleUrls: ['./what-is-indoor-cricket.component.css']
})
export class WhatIsIndoorCricketComponent implements OnInit {
  @Input() image: string;

  constructor() {
    this.image = "girls-celebrating";
  }

  ngOnInit() {
  }

}
