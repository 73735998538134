import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegistrationService} from "../registration.service";
declare function require(name:string);

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    password2: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });

  rSERVICE:RegistrationService;
  hide = true;
  loading = false;
  error = '';

  constructor(registerationservice:RegistrationService) {
    this.rSERVICE = registerationservice;
  }

  ngOnInit() {
  }

  log(e) {
    console.log(e);
  }

  async register() {
    if (this.form.invalid) {
      return;
    }
    if (this.form.value.password.length < 8) {
      this.error = "Password should be atleast 8 characters long."
      return;
    }
    if (this.form.value.password !=  this.form.value.password2) {
      this.error = "Confirm password doesn't match."
      return;
    }
    this.loading = true;
    this.error = '';
    console.log("Push to Firebase");
    const user = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      pass: this.form.value.password
    }
    try {
      const mdcDialog = require('@material/dialog');
      const MDCDialog = mdcDialog.MDCDialog;
      var dialog = new MDCDialog(document.querySelector('#confirmationDialog'));
      dialog.show();
      await this.rSERVICE.save(user,false);
    } catch (e) {
      console.log(e);

    } finally {
      this.loading = false;
    }
  }
}
