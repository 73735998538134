import { Injectable, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore'
import {Observable} from 'rxjs/Observable';
import {RegisteredUser} from "./registration/RegisteredUser";
import { AngularFireDatabase } from 'angularfire2/database';
import 'rxjs/add/operator/map'
import * as firebase from 'firebase/app'
import {AuthserviceService} from "./authservice.service";
import {_catch} from "rxjs/operator/catch";
import {AngularFireAuth} from "angularfire2/auth";




@Injectable()
export class RegistrationService implements OnInit{


  registeredUsers: AngularFirestoreCollection<RegisteredUser>
  rUsers: Observable<RegisteredUser[]>
  snapshot: any
  authService: AuthserviceService;

  constructor(private afs: AngularFirestore, authService: AuthserviceService, private auth: AngularFireAuth) {
    this.authService = authService;
  }

  ngOnInit(): void {

  }


  async save(rUser:RegisteredUser,doAuth:boolean){

    this.afs.collection('users').add({
      firstName: rUser.firstName,
      lastName: rUser.lastName,
      email: rUser.email
    })

    await this.authService.signUP(rUser,doAuth);
    //this.afs.collection('users').add(JSON.parse(JSON.stringify(rUser)));
    console.log("saved");

  }

}
