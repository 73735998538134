import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminItemsComponent} from './items/admin-items.component';
import {AdminUsersComponent} from './users/admin-users.component';
import {AdminNavComponent} from './nav/admin-nav.component';
import {AdminTabsComponent} from './tabs/admin-tabs.component';
import {RouterModule, Routes} from '@angular/router';
import {AdminMainComponent} from './main/admin-main.component';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {UserAdminService} from './services/user-admin.service';
import {MatDialogModule} from '@angular/material/dialog';
import {ItemAdminService} from './services/item-admin.service';
import {RegistrationService} from '../registration.service';
import {AuthserviceService} from '../authservice.service';

export const routesAdmin:Routes = [
  {path:'admin', component:AdminNavComponent},
];

export const routing: ModuleWithProviders = RouterModule.forChild(routesAdmin)

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule
  ],
  declarations: [
    AdminItemsComponent,
    AdminUsersComponent,
    AdminNavComponent,
    AdminTabsComponent,
  ],
  exports: [AdminItemsComponent, AdminUsersComponent, AdminNavComponent,AdminTabsComponent],
  providers: [UserAdminService, ItemAdminService, RegistrationService,AuthserviceService]
})
export class AdminModule {}
