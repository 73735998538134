import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from './../environments/environment';
import {AdminModule} from './admin-module/admin.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { JuniorComparisonComponent } from './junior-comparison/junior-comparison.component';
import { KidsComponent } from './kids/kids.component';
import { BoysComponent } from './boys/boys.component';
import { GirlsComponent } from './girls/girls.component';
import { ClubComponent } from './club/club.component';
import { AdultComparisonComponent } from './adult-comparison/adult-comparison.component';
import { WhatIsIndoorCricketComponent } from './what-is-indoor-cricket/what-is-indoor-cricket.component';
import { SloganComponent } from './slogan/slogan.component';
import { CricketForAllComponent } from './cricket-for-all/cricket-for-all.component';
import { GameComponent } from './game/game.component';
import { RulesComponent } from './rules/rules.component';
import { ParentsComponent } from './parents/parents.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import {AdultsComponent} from './adult/adult.component';
import { PaymentComponent } from './payment/payment.component';
import {AngularFirestore} from 'angularfire2/firestore';
import { StoreBarComponent } from './store-bar/store-bar.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AuthserviceService} from './authservice.service';
import { SigninComponent } from './signin/signin.component';
import {AdminMainComponent} from './admin-module/main/admin-main.component';
import {routing} from './admin-module/admin.module';
import {MatCardModule} from '@angular/material';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import 'hammerjs';

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'junior-comparison', component: JuniorComparisonComponent},
  {path: 'kids', component: KidsComponent},
  {path: 'boys', component: BoysComponent},
  {path: 'girls', component: GirlsComponent},
  {path: 'adults', component: AdultsComponent},
  {path: 'game', component: GameComponent},
  {path: 'rules', component: RulesComponent},
  {path: 'parents', component: ParentsComponent},
  {path: 'signin', component:SigninComponent},
  {path:'register', component:SignupComponent},
]

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    ContactComponent,
    HomeComponent,
    JuniorComparisonComponent,
    KidsComponent,
    BoysComponent,
    GirlsComponent,
    AdultsComponent,
    ClubComponent,
    AdultComparisonComponent,
    WhatIsIndoorCricketComponent,
    SloganComponent,
    CricketForAllComponent,
    GameComponent,
    RulesComponent,
    ParentsComponent,
    SideNavigationComponent,
    PaymentComponent,
    StoreBarComponent,
    SigninComponent,
    AdminMainComponent,
    LoadingSpinnerComponent,
    SignupComponent,
  ],
  imports: [
    MatProgressSpinnerModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.config),
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    MatButtonToggleModule,
    AdminModule,
    routing,
    MatCardModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
  ],
  providers: [AngularFirestore, AuthserviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
