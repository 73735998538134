import { Injectable } from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
@Injectable()
export class ItemAdminService {

  constructor(private afs: AngularFirestore) { }



  modifyItems(cat:string,itstyle:string,itname:string,itsize:string,itimlink:string,itprice:string,docID:string) {
    const db = firebase.firestore();
    const docRef = db.collection('store-items').doc(docID);

    return docRef.update({
      category:cat,
      itemStyle: itstyle,
      itemName: itname,
      itemSize: itsize,
      itemLink: itimlink,
      itemPrice: itprice
    }).then( function () {
      console.log('Doc Updated')
      alert("Document successfully updated");
    }).catch(function (error) {
      console.log('Error: ' + error);
    })
  }

  deleteItem(docID:string) {

    const db = firebase.firestore();

    db.collection('store-items').doc(docID).delete().then(function () {
      console.log('Item sucessfully Deleted')
      alert('Item has been sucessfully Deleted')
    }).catch(function () {
      console.error('Error removing Item')
    })

  }

  addItem(cat:string,itstyle:string,itname:string,itsize:string,itimlink:string,itprice:string){
    const db = firebase.firestore();
    const docRef = db.collection('store-items');

    docRef.add({
      category:cat,
      itemStyle: itstyle,
      itemName: itname,
      itemSize: itsize,
      itemLink: itimlink,
      itemPrice: itprice
    }).then(function () {
      alert('Item Added!')
    })
  }
}
