import { Component, OnInit, AfterContentInit } from '@angular/core';

@Component({
  selector: 'site-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterContentInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    let a = '<jx-footer\n' +
      '  image-url="https://objex.tech/assets/img/logo.png"\n' +
      '  image-href="https://objex.tech/"\n' +
      '  social-links=\'[\n' +
      '    {"name": "facebook", "link": "https://facebook.com/objex"},\n' +
      '    {"name": "twitter", "link": "https://www.twitter.com/objex"},\n' +
      '    {"name": "linkedin", "link": "https://www.linkedin.com/company/objex"},\n' +
      '    {"name": "instagram", "link": "https://www.instagram.com/objex.tech"}\n' +
      '  ]\'\n' +
      '  company-links=\'[\n' +
      '    {\n' +
      '        "name": "Home",\n' +
      '        "links": [\n' +
      '            {\n' +
      '                "name": "Our Services",\n' +
      '                "link": "https://objex.tech/#services"\n' +
      '            },\n' +
      '            {\n' +
      '                "name": "Our Team",\n' +
      '                "link": "https://objex.tech/#team"\n' +
      '            }\n' +
      '        ]\n' +
      '    },\n' +
      '    {\n' +
      '        "name": "Company",\n' +
      '        "links": [\n' +
      '            {\n' +
      '                "name": "Contact us",\n' +
      '                "link": "https://objex.tech/#contact-us;"\n' +
      '            },\n' +
      '            {\n' +
      '                "name": "Blog",\n' +
      '                "link": "https://blog.objex.tech/"\n' +
      '            }\n' +
      '        ]\n' +
      '    }\n' +
      ']\'\n' +
      '></jx-footer>';
    document.getElementById('sponsor-footer').innerHTML += a;
  }

}
