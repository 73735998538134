import { Injectable } from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import {RegistrationService} from '../../registration.service';
import {RegisteredUser} from '../../registration/RegisteredUser';
import {AuthserviceService} from '../../authservice.service';
//import * as admin from 'firebase-admin';
declare function require(name:string);


@Injectable()
export class UserAdminService {
  registerService:RegistrationService;
  authService:AuthserviceService

  constructor(private afs: AngularFirestore, private registerS:RegistrationService, private auth:AuthserviceService) {
    this.registerService = registerS
    this.authService = auth;
  }

  modifyUser(firstName1:string, lastName2:string, docID:string) {
    const db = firebase.firestore();
    const docRef = db.collection('users').doc(docID);

    return docRef.update({
      firstName:firstName1,
      lastName: lastName2
    }).then( function () {
      console.log('Doc Updated')
      alert("Document successfully updated");
    }).catch(function (error) {
      console.log('Error: ' + error);
    })
  }

  deleteUser(email:string, id:string) {
    const db = firebase.firestore();

    // var serviceAccount = require('.././cricketottawa-dev-firebase-adminsdk-zxvoy-0a6469e4c2.json');
    //
    // admin.initializeApp({
    //   credential:admin.credential.cert(serviceAccount),
    //   databaseURL: "https://cricketottawa-dev.firebaseio.com"
    // });

    db.collection('users').doc(id).delete().then(function () {
      console.log('User sucessfully Deleted')
      alert('User has been sucessfully Deleted')
    }).catch(function () {
      console.error('Error removing User')
    })
  }

  addUser(firstName:string,lastName:string, email:string, defaultPass:string) {
    const rUser:RegisteredUser = {
      firstName,
      lastName,
      email,
      pass: defaultPass
    };

    this.registerService.save(rUser,true)
  }


}
