import { Component, OnInit } from '@angular/core';
import {AuthserviceService} from "../authservice.service";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  email:string
  pass:string
  emailVM:string
  passVM:string
  authService: AuthserviceService;
  constructor(authService: AuthserviceService, ) {
    this.authService = authService;
    this.email = ''
    this.pass = ''

  }

  ngOnInit() {
  }

  signIN(){

    if (this.email != '' && this.pass != ''){
      this.authService.signIN(this.email,this.pass);
    }

    else{
      alert('Email or Password is empty');
    }
  }

}
