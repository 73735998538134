import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adult-comparison',
  templateUrl: './adult-comparison.component.html',
  styleUrls: ['./adult-comparison.component.css']
})
export class AdultComparisonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
