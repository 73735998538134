import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-bar',
  templateUrl: './store-bar.component.html',
  styleUrls: ['./store-bar.component.css']
})
export class StoreBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
