import { Component,OnInit,Input} from '@angular/core';
declare const $: any;

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  ngOnInit() {
    $("#navTop").css("z-index", 100)
  }

  openNav() {
    $("openDrawerHeight").css("width", "100%");
    var drawer = $('.mdl-layout__drawer');
    if(drawer){
      drawer.toggleClass('is-visible');
    }
    //$(".nav-top-bar").css("background", "rgba(16, 49, 76, 0.9)");
    //$("#white-hamburger").css("visibility","hidden");
  }

  onOpen(){
    $("navTop").css("z-index", "100")
  }

  closeNav() {
    var drawer = $('.mdl-layout__drawer');
    if(drawer){
      drawer.toggleClass('is-visible');
    }
    // $("#siteSideNav").css("width", "0%");
    // $(".nav-top-bar").css("background", "rgb(16, 49, 76)");
    // $("#white-hamburger").css("visibility","visible");
  }
}
