import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements OnInit {
  @Input() image: string;

  constructor() {
    this.image = "indoor";
  }

  ngOnInit() {
    console.log("side-nav "+ this.image);
  }

}
