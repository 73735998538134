import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'junior-learning-comparison',
  templateUrl: './junior-comparison.component.html',
  styleUrls: ['./junior-comparison.component.css']
})
export class JuniorComparisonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
