import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore'
import {Observable} from 'rxjs/Observable';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app'
import 'rxjs/add/operator/map';
import {ItemFirebase} from '../Classes/itemFirebase';
import {ItemAdminService} from '../services/item-admin.service';
declare function require(name:string)

@Component({
  selector: 'app-admin-items',
  templateUrl: './admin-items.component.html',
  styleUrls: ['./admin-items.component.css']
})
export class AdminItemsComponent implements OnInit {

  snapshot: any
  DialogItemName: string;
  DialogItemCat:string;
  DialogItemStyle:string;
  DialogItemSize:string;
  DialogItemLink:string;
  DialogItemPrice:string;
  allItems: ItemFirebase[];
  itService: ItemAdminService;
  itemName:string
  itemCat:string;
  itemStyle: string;
  itemSize: string;
  itemLink: string;
  itemPrice:string

  constructor(private afs: AngularFirestore, private itemService: ItemAdminService) {
    this.DialogItemName='';
    this.DialogItemCat = '';
    this.DialogItemStyle = '';
    this.DialogItemSize = '';
    this.DialogItemLink = '';
    this.DialogItemPrice = '';
    this.itService = itemService;
    this.itemName = '';
    this.itemCat = '';
    this.itemStyle = '';
    this.itemSize = '';
    this.itemLink = '';
    this.itemPrice = '';
   }

  ngOnInit() {

    const db = firebase.firestore();
    const docRef = db.collection('store-items');


    docRef.get().then((querySnapshot) => {
      this.allItems = new Array(querySnapshot.size);
      querySnapshot.forEach((doc) => {
        console.log(querySnapshot.size)
        doc.data().id = doc.id
        const item = new ItemFirebase()
        item.itemName = doc.data().itemName;
        item.category = doc.data().category;
        item.itemPrice = doc.data().itemPrice;
        item.itemImageLink = doc.data().itemImageLink;
        item.itemSize = doc.data().itemSize;
        item.itemStyle = doc.data().itemStyle;
        item.docID = doc.id;
        this.allItems.push(item);
        console.log(item);
      });
    });
  }

  showMore(cat:string,itstyle:string,itname:string,itsize:string,itimlink:string,itprice:string){

    this.DialogItemName = itname;
    this.DialogItemCat = cat;
    this.DialogItemStyle = itstyle;
    this.DialogItemSize = itsize;
    this.DialogItemLink = itimlink;
    this.DialogItemPrice = itprice;

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#confirmationDialog'));

    dialog.show();
  }

  showModify(cat:string,itstyle:string,itname:string,itsize:string,itimlink:string,itprice:string){
    this.DialogItemName = itname;
    this.DialogItemCat = cat;
    this.DialogItemStyle = itstyle;
    this.DialogItemSize = itsize;
    this.DialogItemLink = itimlink;
    this.DialogItemPrice = itprice;

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#MODIFY'));

    dialog.show();
  }

  showDelete(cat:string,itstyle:string,itname:string,itsize:string,itimlink:string,itprice:string){
    this.DialogItemName = itname;
    this.DialogItemCat = cat;
    this.DialogItemStyle = itstyle;
    this.DialogItemSize = itsize;
    this.DialogItemLink = itimlink;
    this.DialogItemPrice = itprice;

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#deleteDialog'));

    dialog.show();
  }

  showAdd(){
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#addItemDialog'));

    dialog.show();
  }

  modify(){
    let i:number;
    for(i = 0; i<this.allItems.length; i++){
      if(this.allItems[i] != null){
        if(this.DialogItemStyle === this.allItems[i].itemStyle){
          this.itService.modifyItems(this.DialogItemCat,this.DialogItemStyle,this.DialogItemName,this.DialogItemSize,this.DialogItemLink,this.DialogItemPrice,this.allItems[i].docID);
        }
      }
    }
  }

  delete(){
    let i:number;
    for(i = 0; i<this.allItems.length; i++){
      if(this.allItems[i] != null){
        if(this.DialogItemStyle === this.allItems[i].itemStyle){
          this.itService.deleteItem(this.allItems[i].docID);
        }
      }
    }
  }

  addItems(){
    this.itService.addItem(this.itemCat,this.itemStyle,this.itemName,this.itemSize,this.itemLink,this.itemPrice);
  }

}
