import {Component, Input, OnInit} from '@angular/core';
import * as firebase from 'firebase/app';
import {AngularFirestore, AngularFirestoreCollection} from 'angularfire2/firestore';
import {Observable} from 'rxjs/Observable';
import {RegisteredUser} from '../../registration/RegisteredUser';
import {UserAdminService} from '../services/user-admin.service';
import {RegisteredUserFirebase} from '../Classes/RegisteredUserFirebase';
import {MatDialog} from '@angular/material';
declare function require(name:string);

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  usersFirebase: AngularFirestoreCollection<RegisteredUser>;
  users: Observable<RegisteredUser[]>;
  userService: UserAdminService;
  allUsers: RegisteredUserFirebase[];
  DialogUserFN: string;
  DialogUserLN: string;
  DialogUserEM: string;
  FirstName:string;
  LastName:string;
  Email:string

  constructor(private afs: AngularFirestore, private uService: UserAdminService) {
    this.userService = uService;
    this.DialogUserFN = '';
    this.DialogUserLN = '';
    this.DialogUserEM = '';
    this.FirstName = '';
    this.LastName = '';
    this.Email = '';

  }


  ngOnInit() {
    this.usersFirebase = this.afs.collection('users');
    this.users = this.usersFirebase.valueChanges();

    const db = firebase.firestore();
    const docRef = db.collection('users');

    docRef.get().then((querySnapshot) => {
      this.allUsers = new Array(querySnapshot.size);
      querySnapshot.forEach((doc) => {
        console.log(querySnapshot.size)
        doc.data().id = doc.id
        const registeredUser = new RegisteredUserFirebase();
        registeredUser.firstName = doc.data().firstName;
        registeredUser.lastName = doc.data().lastName;
        registeredUser.email = doc.data().email;
        registeredUser.docid = doc.id;
        this.allUsers.push(registeredUser);
        console.log(registeredUser);
      });
    });
  }
  deleteUser(email:string) {
    console.log(email)
      let i: number;
       for (i = 0; i<this.allUsers.length; i++) {
         if(this.allUsers[i] != null){
           if (email === this.allUsers[i].email) {
             this.userService.deleteUser(this.allUsers[i].email, this.allUsers[i].docid);
           }
         }
       }
  }

  modifyUser(fn:string, ln:string, em:string){

    this.DialogUserFN = fn;
    this.DialogUserLN = ln;
    this.DialogUserEM = em;

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#ModifyUserDialog'));

    dialog.show();

  }

  modifyUserCall(){
    let i:number;
    for(i = 0; i<this.allUsers.length; i++){
      if(this.allUsers[i] != null){
        if(this.DialogUserEM === this.allUsers[i].email){
          this.userService.modifyUser(this.DialogUserFN,this.DialogUserLN,this.allUsers[i].docid)
        }
      }
    }
  }

  addUserDialog(){
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;

    var dialog = new MDCDialog(document.querySelector('#AddUserDialog'));

    dialog.show();
  }

  addUserCall(){
    this.userService.addUser(this.FirstName,this.LastName,this.Email,'DEFAULT')
  }

}
